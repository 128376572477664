import React, { useEffect, useRef, useState } from 'react';
import { DataGridPremium, GridActionsCellItem, GridCellSelectionModel, GridColDef, GridRowId, GridRowParams, GridRowsProp, GridToolbar, GridValidRowModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, InputLabel, Link, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { styled } from '@mui/material/styles';
import { FileDownload } from '@mui/icons-material';
import { isAction } from '@reduxjs/toolkit';
import SignedPDF from './SignedPDF';
import CloseIcon from '@mui/icons-material/Close';
import { debug } from 'console';
import { useNavigate } from 'react-router-dom';
import SaveSignedPDF from './SaveSignedPDF';
import SignatureCanvas from 'react-signature-canvas';
import { PDFDocument } from 'pdf-lib';



export default function SignPDFDoc() {

    const [orderNo, setOrderNo] = React.useState('');
    const [bolNo, setBolNo] = React.useState('');
    const [statusData, setStatusData] = React.useState([])
    const signatureCanvasRef = useRef<SignatureCanvas>(null);
    const [isSigned, setIsSigned] = React.useState(0);
    const [signedFileName, setsignedFileName] = React.useState('');

    const [open, setOpen] = React.useState(false);

    const [rowClicked, setrowClicked] = React.useState(false);

    const [gridValidRowModel, setGridValidRowModel] =
        React.useState<GridValidRowModel>({});

    let cellClicked = false
    const [balanceData, setBalanceData] = React.useState([])
    const [openPopUp, setOpenPopUp] = React.useState(false)
    const currCustomer = localStorage.getItem("currentOwner")
    const currWHS = localStorage.getItem("currentWHS")

    const [orderDataBillShipTo, setOrderDataBillShipTo] = React.useState([])
    const tokenValue: any = localStorage.getItem("accessToken")
    const apiUrlUploadFile: any = "https://igdapps.igdistribution.net/igdcpapi/api/user/UploadSignedBOLFile"
    const apiUrlBal: any = "https://igdapps.igdistribution.net/igdcpapi/api/user/getRprt_billOfLadingDtls"
    const apiUrlGetBol: any = "https://igdapps.igdistribution.net/igdcpapi/api/user/GetBOLfileToSign"
    const apiUrlBillToShipTo: any = "https://igdapps.igdistribution.net/igdcpapi/api/user/GetOrderBillToShipToDtls"

    const navigate = useNavigate();
    const apiRef = useGridApiRef();
    const [cellSelectionModel, setCellSelectionModel] =
        React.useState<GridCellSelectionModel>({});

    const [state, setState] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });
    const { start, end } = state;
    const handleCallback = (start: any, end: any) => {

        setState({ start, end });
        const labelStart =
            start.format('YYYY-MM-DD')
        const labelEnd =
            end.format('YYYY-MM-DD')

        getBalApiData(labelStart, labelEnd)

    };
    const label =
        start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');



    async function getBalApiData(frmDate: string, toDate: string) {
        const responseBal = await getDashData_balance(
            {
                "custCode": currCustomer,
                "whseCode": currWHS,
                "carrier": "all",
                "status": "all"
            })
        if (responseBal) {
            setBalanceData(responseBal.Payload)

        }
    }

    async function UploadSignedMbolFile(params: any) {

        const formData = new FormData();
        const currentCust: any = currCustomer
        const currentWHS: any = currWHS

        formData.append('file', params.file);
        formData.append('bolNumber', params.bolNumber);
        formData.append('mBolNumber', params.mBolNumber);
        formData.append('custCode', currentCust);
        formData.append('whseCode', currentWHS);
        return fetch(apiUrlUploadFile, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${tokenValue}`
            },
            body: formData,
        }).then((data) => data.json())
            .catch((error) => {
                console.log("Failed")
                localStorage.clear();
                navigate("/login");
                window.location.reload();
            })
    }

    async function getDashData_balance(params: any) {
        return fetch(apiUrlBal, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;',
                Authorization: `Bearer ${tokenValue}`
            },
            body: JSON.stringify(params),
        }).then((data) => data.json())
            .catch((error) => {
                console.log("Failed")
                localStorage.clear();
                navigate("/login");
                window.location.reload();
            })
    }

    async function getBOlApiData(bol: string) {
        const responseBal = await getDashData_BOL(
            {
                "custCode": currCustomer,
                "whseCode": currWHS,
                "bolNumber": bol,
                "mBolNumber": ""
            })
        if (responseBal) {
            setsignedFileName("https://igdapps.igdistribution.net/igdcpapi/" + responseBal.Payload)
            //  setOpenPopUp(true)
            setOpen(true)
        }
    }

    async function getDashData_BOL(params: any) {
        return fetch(apiUrlGetBol, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;',
                Authorization: `Bearer ${tokenValue}`
            },
            body: JSON.stringify(params),
        }).then((data) => data.json())
            .catch((error) => {
                console.log("Failed")
                localStorage.clear();
                navigate("/login");
                window.location.reload();
            })
    }

    useEffect(() => {
        try {
            const startdate =
                start.format('YYYY-MM-DD')
            const endDate =
                end.format('YYYY-MM-DD')


            getBalApiData(startdate, endDate)
        }
        catch (e) {
            //log error
        }
    },
        []);



    const columns: GridColDef[] = [

        { field: 'OrderNumber', width: 150, headerClassName: "headingbg-color", headerName: 'Order Number' },
        { field: 'BLNumber', headerClassName: "headingbg-color", width: 180, headerName: 'BL Number' },
        { field: 'OrderType', headerClassName: "headingbg-color", width: 200, headerName: 'Order Type' },

        { field: 'CarrierCode', width: 200, headerClassName: "headingbg-color", headerName: 'Carrier SCAC' },
        { field: 'CarrierName', width: 300, headerClassName: "headingbg-color", headerName: 'Carrier Name' },
        { field: 'Tracking_PROnumber', width: 300, headerClassName: "headingbg-color", headerName: 'Tracking PROnumber' },
        { field: 'sourceBOLFile', width: 200, headerClassName: "headingbg-color", headerName: 'source BOL File' },

    ]

    const rows: GridRowsProp = balanceData.map((c: any, index) => ({
        id: index,
        OrderNumber: c.OrderNumber,
        BLNumber: c.BLNumber,
        BLStatus: c.BLStatus,
        OrderType: c.OrderType,
        BillTo: c.BillTo,
        ShipTo: c.ShipTo,
        CarrierCode: c.CarrierCode,
        CarrierName: c.CarrierName,
        FreightPaymentType: c.FreightPaymentType,
        Tracking_PROnumber: c.Tracking_PROnumber,
        SealNumber: c.SealNumber,
        isBOLSigned: c.isBOLSigned,
        signedBOLFile: c.signedBOLFile,
        sourceBOLFile : c.sourceBOLFile
    }));


    const StyledGridOverlay = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& .ant-empty-img-1': {
            fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
            fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
    }));

    const handleClose = () => {
        setOpen(false);
        setrowClicked(false)
    };

    const handleCloseDetails = () => {
        setOpenPopUp(false);
        setrowClicked(false)
    };


    const handlerRowClick = (rowprops: any, cellprops: any) => {

        if (rowClicked) {
            setrowClicked(true)
            //setOpenPopUp(false)
            setOrderNo(rowprops.row.OrderNumber)
            setBolNo(rowprops.row.BLNumber)
            setIsSigned(rowprops.row.isBOLSigned)
            const filepath = "https://igdapps.igdistribution.net/igdcpapi/"
            setsignedFileName(filepath + rowprops.row.signedBOLFile)
            if (rowprops.row.isBOLSigned) {
                // setOpen(false)
                // setsignedFileName("")
                // window.open(filepath + rowprops.row.signedBOLFile, "_blank");
            }
            else {

                //call get api
                //take source from new api change
                setsignedFileName(filepath + rowprops.row.sourceBOLFile)
                setOpen(true)
                // getBOlApiData(rowprops.row.BLNumber)

            }
        }

    }

    async function getBillToShipToDetails(params: any) {
        const responseOrders = await getBillToShipTo_Details(
            {
                "custCode": currCustomer,
                "whseCode": currWHS,
                "orderNo": params
            })

        if (responseOrders) {
            setOrderDataBillShipTo(responseOrders.Payload)
        }
    }

    async function getBillToShipTo_Details(params: any) {
        return fetch(apiUrlBillToShipTo, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;',
                Authorization: `Bearer ${tokenValue}`
            },
            body: JSON.stringify(params),
        }).then((data) => data.json())
            .catch((error) => {
                console.log("Failed")
                navigate("/login")
            })
    }

    const handleCellSelectionModelChange = React.useCallback(
        (newModel: GridCellSelectionModel) => {

            Object.entries(newModel).forEach(([id, fields]) => {

                if (fields.BillTo || fields.ShipTo) {
                    setOpen(false)
                    setsignedFileName("")
                    const updatedRow = apiRef.current.getRow(id);
                    getBillToShipToDetails(updatedRow.OrderNumber)
                    setOpenPopUp(true)
                    cellClicked = true
                    setrowClicked(false)
                    setGridValidRowModel(updatedRow)
                }
                else {
                    setrowClicked(true)
                    cellClicked = false
                }
            })

            // setCellSelectionModel(newModel);
        },
        [],
    );

    const handleClearSignature = () => {
        signatureCanvasRef.current?.clear();
    };

    const handleSavePDF = async () => {

        if (!signatureCanvasRef.current || signatureCanvasRef.current.isEmpty()) {
            alert('Please provide a signature.');
            return;
        }
        const signatureImage = signatureCanvasRef.current.toDataURL();
        var pdfToSign = signedFileName

        const existingPdfBytes = await fetch(pdfToSign).then(res => res.arrayBuffer());
        const bytes = new Uint8Array(existingPdfBytes);
        const pdfDoc = await PDFDocument.load(bytes);

        const pages = await Promise.all(
            Array.from(Array(pdfDoc.getPageCount()).keys()).map(async (pageIndex) => {
                const page = await pdfDoc.getPage(pageIndex);
                const pngImage = await pdfDoc.embedPng(signatureImage);
                const pngDims = pngImage.scale(0.3); // Adjust scale if needed
                page.drawImage(pngImage, {
                    x: 380, // Adjust x and y coordinates to position the signature
                    y: 220,
                    width: pngDims.width,
                    height: pngDims.height,
                });
            })
        );

        const modifiedPdfBytes = await pdfDoc.save();
        const file = new File([modifiedPdfBytes], "signedPDF.pdf", { type: 'application/pdf' });

        uploadSignedFile(file)

    };

    async function uploadSignedFile(file: any) {

        const responseFile = await UploadSignedMbolFile(
            {
                "custCode": currCustomer,
                "whseCode": currWHS,
                "bolNumber": bolNo,
                "mBolNumber": "",
                "file": file
            })

        if (responseFile) {
            var msg = responseFile.Message != null ? responseFile.Message : (responseFile.errors != null ? responseFile.errors[""][0] : responseFile.title);
            alert(msg);
            setOpen(false)
        }
    }


    return (

        <div className='ml-2' >
            <div className='mt-8 mb-6 text-center pageHeading'>BOL Report details </div>
            <div className='pb-2' style={{ width: '30%' }}>
            </div>

            <Box sx={{ width: '100%', height: 650, padding: '0 5%' }}>
                <DataGridPremium
                    slots={{ toolbar: GridToolbar }}
                    apiRef={apiRef}
                    cellSelectionModel={cellSelectionModel}
                    onCellSelectionModelChange={handleCellSelectionModelChange}
                    cellSelection={true}
                    onRowClick={handlerRowClick}

                    columns={columns}
                    rows={rows}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        }
                    }}
                ></DataGridPremium>
            </Box>
            {openPopUp && (
                <Box
                    sx={{ width: '100%', height: 500 }}>
                    <Dialog
                        open={openPopUp}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        style={{
                            height: '100%', width: '100%', alignItems: 'center'
                        }}
                    >
                        <DialogTitle className='colordivSumHead font-bold' id="form-dialog-title">Bill To / Ship To Details: </DialogTitle>
                        <DialogContent>

                            <Box sx={{ width: 800, height: 550 }}>

                                {orderDataBillShipTo?.map((s: any) => {
                                    return (
                                        <>
                                            <table style={{ height: '60%', width: '100%' }}>


                                                <th className='colordivSumHead font-bold mr-8' >Bill To Details :</th>
                                                <tr>
                                                    <td className="font-bold">Order Number</td>
                                                    <td>{s.OrderNumber}</td>
                                                </tr>
                                                <tr><td className="font-bold">BillTo Name</td>
                                                    <td>{s.BillTo_Name}</td>
                                                </tr>

                                                <tr><td className="font-bold">BillTo Address</td>
                                                    <td>{s.BillTo_Address}</td>
                                                </tr>
                                                <tr><td className="font-bold">BillTo City</td>
                                                    <td>{s.BillTo_City}</td>
                                                </tr>

                                                <tr><td className="font-bold">BillTo State</td>
                                                    <td>{s.BillTo_State}</td>
                                                </tr>
                                                <tr><td className="font-bold">BillTo Country</td>
                                                    <td>{s.BillTo_Country}</td>
                                                </tr>

                                                <tr><td className="font-bold">BillTo ZipCode</td>
                                                    <td>{s.BillTo_ZipCode}</td>
                                                </tr>
                                                <tr><td className="font-bold">BillTo PhoneNo</td>
                                                    <td>{s.BillTo_PhoneNo}</td>
                                                </tr>

                                                <tr><td className="font-bold">BillTo Contact</td>
                                                    <td>{s.BillTo_Contact}</td>
                                                </tr>
                                                <tr><td className="font-bold">BillTo VAT</td>
                                                    <td>{s.BillTo_VAT}</td>
                                                </tr>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </table>
                                            <table style={{ height: '60%', width: '100%' }}>

                                                <th className='colordivSumHead font-bold mr-8' >Ship To Details :</th>

                                                <tr>
                                                    <td className="font-bold">Order Number</td>
                                                    <td>{s.OrderNumber}</td>
                                                </tr>
                                                <tr><td className="font-bold">ShipTo Name</td>
                                                    <td>{s.ShipTo_Name}</td>
                                                </tr>

                                                <tr><td className="font-bold">ShipTo Address</td>
                                                    <td>{s.ShipTo_Address}</td>
                                                </tr>
                                                <tr><td className="font-bold">ShipTo City</td>
                                                    <td>{s.ShipTo_City}</td>
                                                </tr>

                                                <tr><td className="font-bold">ShipTo State</td>
                                                    <td>{s.ShipTo_State}</td>
                                                </tr>
                                                <tr><td className="font-bold">ShipTo Country</td>
                                                    <td>{s.ShipTo_Country}</td>
                                                </tr>

                                                <tr><td className="font-bold">ShipTo ZipCode</td>
                                                    <td>{s.ShipTo_ZipCode}</td>
                                                </tr>
                                                <tr><td className="font-bold">ShipTo PhoneNo</td>
                                                    <td>{s.ShipTo_PhoneNo}</td>
                                                </tr>

                                                <tr><td className="font-bold">ShipTo Contact</td>
                                                    <td>{s.ShipTo_Contact}</td>
                                                </tr>
                                                <tr><td className="font-bold">ShipTo Fax</td>
                                                    <td>{s.ShipTo_Fax}</td>
                                                </tr>

                                                <tr><td className="font-bold">ShipTo VAT</td>
                                                    <td>{s.ShipTo_VAT}</td>
                                                    <td></td>
                                                </tr>

                                            </table>
                                        </>
                                    )
                                })}



                            </Box>

                        </DialogContent>
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseDetails}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Dialog>
                </Box>
            )
            }
            {open && (
                // <SaveSignedPDF
                //     fileName={signedFileName}
                //     action={handleClose}
                //     isSigned={isSigned}
                //     showSign={false}
                // ></SaveSignedPDF>
                <div className="modalContainer modal-signature-pad">
                    <div className="modal">
                        <div className="heading-container">
                            <h3>Please add your digital signature:-</h3>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 10,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <div className="signature-pad">
                            <SignatureCanvas
                                canvasProps={{
                                    width: 500,
                                    height: 300,
                                    className: "sigCanvas",
                                }}
                                ref={signatureCanvasRef}
                            />
                        </div>
                        <div className="modal__bottom">
                            <button
                                className="btn btn-default"
                                onClick={handleClearSignature}
                            >
                                Clear
                            </button>
                            <button className="btn btn-primary ml-2" onClick={handleSavePDF}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>

            )}
        </div>

    );
}
